p, label {
    font-size: 1rem;
}

h5, .MuiTypography-h5 {
    font-size: 1.125rem;
}

h4, .MuiTypography-h4 {
    font-size: 1.25rem;
}

h3, .MuiTypography-h3 {
    font-size: 1.5rem;
}

h2, .MuiTypography-h2 {
    font-size: 1.875rem;
}

h1, .MuiTypography-h1 {
    font-size: 1.2rem;
}