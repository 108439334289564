.lds-hourglass, .lds-hourglass:after {
  box-sizing: border-box;
}

.lds-hourglass {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-hourglass:after {
  content: " ";
  width: 0;
  height: 0;
  box-sizing: border-box;
  border: 32px solid;
  border-color: currentColor #0000;
  border-radius: 50%;
  margin: 8px;
  animation: 1.2s infinite lds-hourglass;
  display: block;
}

@keyframes lds-hourglass {
  0% {
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: rotate(0);
  }

  50% {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: rotate(900deg);
  }

  100% {
    transform: rotate(1800deg);
  }
}

.ReactCrop {
  cursor: crosshair;
  max-width: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ReactCrop *, .ReactCrop :before, .ReactCrop :after {
  box-sizing: border-box;
}

.ReactCrop--disabled, .ReactCrop--locked {
  cursor: inherit;
}

.ReactCrop__child-wrapper {
  max-height: inherit;
}

.ReactCrop__child-wrapper > img, .ReactCrop__child-wrapper > video {
  max-width: 100%;
  max-height: inherit;
  touch-action: none;
  display: block;
}

.ReactCrop__crop-selection {
  cursor: move;
  touch-action: none;
  border: 1px dashed #fff;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 0 0 9999em #00000080;
}

.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}

.ReactCrop--circular-crop .ReactCrop__crop-selection {
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #fff, 0 0 0 9999em #00000080;
}

.ReactCrop__crop-selection:focus {
  border-style: solid;
  border-color: #00f;
  outline: none;
}

.ReactCrop--invisible-crop .ReactCrop__crop-selection {
  display: none;
}

.ReactCrop__rule-of-thirds-vt:before, .ReactCrop__rule-of-thirds-vt:after, .ReactCrop__rule-of-thirds-hz:before, .ReactCrop__rule-of-thirds-hz:after {
  content: "";
  background-color: #fff6;
  display: block;
  position: absolute;
}

.ReactCrop__rule-of-thirds-vt:before, .ReactCrop__rule-of-thirds-vt:after {
  width: 1px;
  height: 100%;
}

.ReactCrop__rule-of-thirds-vt:before {
  left: 33.3333%;
}

.ReactCrop__rule-of-thirds-vt:after {
  left: 66.6667%;
}

.ReactCrop__rule-of-thirds-hz:before, .ReactCrop__rule-of-thirds-hz:after {
  width: 100%;
  height: 1px;
}

.ReactCrop__rule-of-thirds-hz:before {
  top: 33.3333%;
}

.ReactCrop__rule-of-thirds-hz:after {
  top: 66.6667%;
}

.ReactCrop__drag-handle {
  position: absolute;
}

.ReactCrop__drag-handle:after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #0003;
  border: 1px solid #ffffffb3;
  outline: 1px solid #0000;
  display: block;
  position: absolute;
}

.ReactCrop__drag-handle:focus:after {
  background: #2dbfff;
  border-color: #00f;
}

.ReactCrop .ord-nw {
  cursor: nw-resize;
  margin-top: -5px;
  margin-left: -5px;
  top: 0;
  left: 0;
}

.ReactCrop .ord-nw:after {
  top: 0;
  left: 0;
}

.ReactCrop .ord-n {
  cursor: n-resize;
  margin-top: -5px;
  margin-left: -5px;
  top: 0;
  left: 50%;
}

.ReactCrop .ord-n:after {
  top: 0;
}

.ReactCrop .ord-ne {
  cursor: ne-resize;
  margin-top: -5px;
  margin-right: -5px;
  top: 0;
  right: 0;
}

.ReactCrop .ord-ne:after {
  top: 0;
  right: 0;
}

.ReactCrop .ord-e {
  cursor: e-resize;
  margin-top: -5px;
  margin-right: -5px;
  top: 50%;
  right: 0;
}

.ReactCrop .ord-e:after {
  right: 0;
}

.ReactCrop .ord-se {
  cursor: se-resize;
  margin-bottom: -5px;
  margin-right: -5px;
  bottom: 0;
  right: 0;
}

.ReactCrop .ord-se:after {
  bottom: 0;
  right: 0;
}

.ReactCrop .ord-s {
  cursor: s-resize;
  margin-bottom: -5px;
  margin-left: -5px;
  bottom: 0;
  left: 50%;
}

.ReactCrop .ord-s:after {
  bottom: 0;
}

.ReactCrop .ord-sw {
  cursor: sw-resize;
  margin-bottom: -5px;
  margin-left: -5px;
  bottom: 0;
  left: 0;
}

.ReactCrop .ord-sw:after {
  bottom: 0;
  left: 0;
}

.ReactCrop .ord-w {
  cursor: w-resize;
  margin-top: -5px;
  margin-left: -5px;
  top: 50%;
  left: 0;
}

.ReactCrop .ord-w:after {
  left: 0;
}

.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}

.ReactCrop__drag-bar {
  position: absolute;
}

.ReactCrop__drag-bar.ord-n {
  width: 100%;
  height: 6px;
  margin-top: -3px;
  top: 0;
  left: 0;
}

.ReactCrop__drag-bar.ord-e {
  width: 6px;
  height: 100%;
  margin-right: -3px;
  top: 0;
  right: 0;
}

.ReactCrop__drag-bar.ord-s {
  width: 100%;
  height: 6px;
  margin-bottom: -3px;
  bottom: 0;
  left: 0;
}

.ReactCrop__drag-bar.ord-w {
  width: 6px;
  height: 100%;
  margin-left: -3px;
  top: 0;
  left: 0;
}

.ReactCrop--new-crop .ReactCrop__drag-bar, .ReactCrop--new-crop .ReactCrop__drag-handle, .ReactCrop--fixed-aspect .ReactCrop__drag-bar, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}

@media (pointer: coarse) {
  .ReactCrop .ord-n, .ReactCrop .ord-e, .ReactCrop .ord-s, .ReactCrop .ord-w {
    display: none;
  }

  .ReactCrop__drag-handle {
    width: 24px;
    height: 24px;
  }
}

p, label {
  font-size: 1rem;
}

h5, .MuiTypography-h5 {
  font-size: 1.125rem;
}

h4, .MuiTypography-h4 {
  font-size: 1.25rem;
}

h3, .MuiTypography-h3 {
  font-size: 1.5rem;
}

h2, .MuiTypography-h2 {
  font-size: 1.875rem;
}

h1, .MuiTypography-h1 {
  font-size: 1.2rem;
}

/*# sourceMappingURL=index.aef966be.css.map */
